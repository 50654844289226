<template>
	<div>
		Bienvenido {{ user.email  }}
		
		<router-link to="/listado-constancias">Listado Constancias</router-link>
	</div>
</template>

<script>

import useAuthUser from '../composables/useAuthUser';

export default {
	data() {
		return {
			user: []
		}
	},
	mounted() {
		
		const { getUser } = useAuthUser();
		this.user = getUser();
	},
	methods: {
		
	},

}
</script>