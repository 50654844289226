<template>
    <div class="row">

        <div class="col-md-12">
			<div class="table-responsive">
				<table class="table table-bordered table-dark text-center">
					<thead>
						<tr>
							<th>#</th>
							<th>RFC</th>
							<th>Razón Social</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, key) in listado_constancias" v-bind:key="key">
							<td>{{ key + 1 }}</td>
							<td>{{ item.rfc }}</td>
							<td class="elipsis">{{ item.razon_social }}</td>
							<td>
								<div class="btn-group btn-group-sm" role="group">
									<button type="button" class="btn btn-success text-white mx-1" v-on:click="getConstancia(item.id)">
										<i class="bi bi-eye"></i>
									</button>
									<button type="button" class="btn btn-danger text-white mx-1" v-on:click="showConstancia(item)">
										<i class="bi bi-file-pdf-fill"></i>
									</button>
									<button type="button" class="btn btn-primary text-white mx-1" v-on:click="updateConstancia(item.id)" :class=" item.actualizado ? 'disabled' : '' ">
										<i class="bi bi-check"></i>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
        </div>

    </div>

	<div class="modal fade" id="newModal" ref="newModal">
		<div class="modal-dialog">
			<!-- Modal content-->
			<div class="modal-content text-dark">
				<div class="modal-header">
					<h4 class="modal-title" style="text-transform: uppercase;">Detalles RFC: {{ constancia.rfc }}</h4>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							
				<div class="row mb-3">
					<div class="col-md-7">
						<label for="razon_social" class="form-label">Razón Social</label>
						<input type="text" class="form-control form-control-sm" id="razon_social" v-model="constancia.razon_social" disabled />
					</div>

					<div class="col-md-5">
						<label for="rfc" class="form-label">RFC</label>
						<input type="text" class="form-control form-control-sm" id="rfc" v-model="constancia.rfc" disabled />
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-8">
						<label for="email" class="form-label">Email</label>
						<input type="email" class="form-control form-control-sm" id="razon_social" v-model="constancia.email" disabled />
					</div>

					<div class="col-md-4">
						<label for="codigo_postal" class="form-label">Código postal</label>
						<input type="text" class="form-control form-control-sm" id="codigo_postal" v-model="constancia.codigo_postal" disabled />
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-6">
						<label for="regimen_fiscal" class="form-label">Régimen fiscal</label>
						<input type="email" class="form-control form-control-sm" id="regimen_fiscal" v-model="compRegimenFiscal" disabled />
					</div>

					<div class="col-md-6">
						<label for="uso_cfdi" class="form-label">Uso CFDI</label>
						<input type="text" class="form-control form-control-sm" id="uso_cfdi" v-model="compUsoCFDI" disabled />
					</div>
				</div>
				
				<hr class="my-4" />

				<div class="col-md-12 text-center">
					<a :href="constancia.download_constancia" class="btn btn-primary" target="_blank" download>
						<i class="bi bi-cloud-download"></i> Descargar
					</a>
				</div>

						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary btn-sm" v-on:click="hideModal">
						<i class="bi bi-x-lg"></i> Cerrar
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="col-md-12 text-center">
		<router-link to="/logout">Salir</router-link>
	</div>
	
</template>

<style>
.table tbody td {
	text-transform: uppercase;
	font-size: .7rem;
}
.table>:not(caption)>*>* {
	padding: 0.25rem 0.5rem!important;
}
.elipsis{
	text-align: left;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>

<script>

import usePocketbase from '../composables/usePocketbase';
import { Modal } from 'bootstrap';

export default {
	data() {
		return {
			constancia: { 
				download_constancia: null,
				razon_social: null,
				rfc: null,
				email: null,
				codigo_postal: null,
				constancia_fiscal: null,
				regimen_fiscal: -1,
				uso_cfdi: -1,
				signature: 'e3a6a9c4110d67b36ff2cc904b07f99e7be64e48',
				expand: {
					regimen_fiscal: [],
					uso_cfdi: []
				}
			},
			newModal : null,
			provider: [],
			listado_constancias: [],
			code: '',
			redirect_url: 'http://localhost:8080/redirect',
		}
	},
	computed: {
		compRegimenFiscal(){

			let expand = this.constancia.expand;
			if (expand.regimen_fiscal) {
				return expand.regimen_fiscal.sat_id + ' - ' + expand.regimen_fiscal.sat_descripcion;
			}
			
			return '';
		},
		compUsoCFDI(){

			let expand = this.constancia.expand;
			if (expand.uso_cfdi) {
				return expand.uso_cfdi.sat_id + ' - ' + expand.uso_cfdi.sat_descripcion;
			}
			
			return '';
		},
	},
	mounted() {
		
		const { pocketbase } = usePocketbase();
		const params = (new URL(window.location)).searchParams;
		const provider = JSON.parse(localStorage.getItem('provider'));

		this.pocketbase = pocketbase;
		this.provider = provider;
		this.code = params.get('code');

		this.getConstancias();
	},

	methods: {
		hideModal(){
			this.newModal.hide();
		},

		updateConstancia(id){

			this.pocketbase
				.collection('constancias_fiscal')
				.update(id, { actualizado: true, signature: 'e3a6a9c4110d67b36ff2cc904b07f99e7be64e48' })
				.then(res => {

					console.log(res)
					this.getConstancias();
					alert("Se ha actualizado el estatus correctamente, si piensa que es un error contacta a soporte");
				})
				.catch(err => alert(err.message))
		},

		showConstancia(item){

			let { id, constancia_fiscal } = item;
			let url = `https://api.media.contacta.mx/api/files/constancias_fiscal/${id}/${constancia_fiscal}`;

			window.open(url, '_blank');
		},

		getConstancia(id){

			this.pocketbase
				.collection('constancias_fiscal')
				.getOne(id, { expand: 'regimen_fiscal,uso_cfdi' })
				.then( res => {
					this.constancia = res;

					return this.pocketbase.getFileUrl(res, res.constancia_fiscal);
				})
				.then(res => {

					this.constancia.download_constancia = res;
					this.newModal = new Modal(this.$refs.newModal);
					this.newModal.show();
				})
				.catch(err => alert(err.message))
		},
		getConstancias(){

			this.pocketbase
				.collection('constancias_fiscal')
				.getFullList(200, { sort: '+actualizado,-created' })
				.then( res => this.listado_constancias = res)
				.catch(err => alert(err.message))
		},
		async userToGoogle(){
			let provider = this.provider;
			let code = this.code;
			let redirect_url = this.redirect_url;

			let { data, error } = await this.pocketbase.collection('users').authWithOAuth2(
				provider[0].name,
				code,
				provider[0].codeVerifier,
				redirect_url
			);

			console.log(data, error)
			console.log(this.pocketbase.authStore)
		}
	},
}
</script>