<template>

	<div class="row">

		<div class="col-md-12 text-center" v-if="loading && !complete">
			<h4 class="mb-4"><i class="bi bi-arrow-repeat"></i> Espere un momento, guardando el registro...</h4>
		</div>

		<div class="col-md-12 text-center" v-if="loading && complete">
			<h4 class="mb-4">Muchas gracias, se ha guardado correctamente!</h4>
			<h5 class="mb-4">Puede cerrar la pagina o espere para ser redirido.</h5>
		</div>
		
		<div class="col-md-12" v-if="!loading">
			<h5 class="mb-4">Llene los datos solicitados</h5>

			<form method="post" action="#" v-on:submit="saveFile">

				<div class="row mb-3">
					<div class="col-md-7">
						<label for="razon_social" class="form-label">Razón Social <span class="text-muted">(Nombre o Denominación Social)</span></label>
						<input type="text" class="form-control form-control-sm" id="razon_social" v-model="constancia.razon_social" placeholder="Empresa de Ejemplo S.A. de C.V." minlength="3" required />
					</div>

					<div class="col-md-5">
						<label for="rfc" class="form-label">RFC <span class="text-muted">(con Homoclave)</span></label>
						<input type="text" class="form-control form-control-sm" id="rfc" v-model="constancia.rfc" placeholder="XXXX001122333" minlength="12" maxlength="14" pattern="[A-Za-z0-9]{12,14}" required />
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-8">
						<label for="email" class="form-label">Email</label>
						<input type="email" class="form-control form-control-sm" id="razon_social" v-model="constancia.email" placeholder="correo@ejemplo.com" required />
					</div>

					<div class="col-md-4">
						<label for="codigo_postal" class="form-label">Código postal</label>
						<input type="text" class="form-control form-control-sm" id="codigo_postal" v-model="constancia.codigo_postal" placeholder="00000" minlength="5" maxlength="5" required />
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-8">
						<label for="regimen_fiscal" class="form-label">Régimen fiscal</label>
						<select class="form-control form-control-sm" id="regimen_fiscal" v-model="constancia.regimen_fiscal" required>
							<option value="-2" disabled selected>Seleccione su régimen fiscal</option>
							<option v-for="(item, key) in regimenes" :value="item.id" v-bind:key="key">
								{{ item.sat_id }} - {{ item.sat_descripcion }}
							</option>
						</select>
					</div>

					<div class="col-md-4">
						<label for="uso_cfdi" class="form-label">Uso CFDI</label>
						<select class="form-control form-control-sm" id="uso_cfdi" v-model="constancia.uso_cfdi" required >
							<option value="-2" disabled>Seleccione el uso del CFDI</option>
							<option v-for="(item, key) in usos_cfdi" :value="item.id" v-bind:key="key">
								{{ item.sat_id }} - {{ item.sat_descripcion }}
							</option>
						</select>
					</div>
				</div>

				<hr class="my-4" />

				<div class="row mb-3">
					<div class="col-md-12">
						<label for="constancia_fiscal" class="form-label">Constancia de Situación Fiscal <span class="text-muted">(Archivo PDF)</span></label>
						<input type="file" class="form-control form-control-sm" id="constancia_fiscal" ref="constancia_fiscal" v-on:change="checkFile" accept=".pdf"  required />
					</div>
				</div>

				<hr class="my-4" />

				<button class="w-100 btn btn-primary btn-xs" type="submit">
					<i class="bi bi-cloud-upload"></i> Guardar
				</button>
			</form>
		</div>
	</div>

</template>

<script>
import usePocketbase from '../composables/usePocketbase';

export default {
	data() {
		return {
			regimenes: [],
			usos_cfdi: [],
			pocketbase: null,
			loading: false,
			complete: false,
			constancia: { 
				razon_social: null,
				rfc: null,
				email: null,
				codigo_postal: null,
				constancia_fiscal: null,
				regimen_fiscal: -1,
				uso_cfdi: -1,
				actualizado: false,
				signature: 'e3a6a9c4110d67b36ff2cc904b07f99e7be64e48',
			}
		}
	},
	mounted() {
		
		const { pocketbase } = usePocketbase();
		this.pocketbase = pocketbase;
		this.loadCatalog();

	},
	methods: {
		async loadCatalog(){
			this.regimenes = await this.pocketbase.collection('regimes_fiscales').getFullList(200, { filter: 'estatus=true', sort: '+sat_id' });
			this.usos_cfdi = await this.pocketbase.collection('uso_cfdi').getFullList(200, { filter: 'estatus=true', sort: '+sat_id' });

		},
		checkFile(){
			this.constancia.constancia_fiscal = this.$refs.constancia_fiscal.files[0];
		},
		saveFile(e){
			e.preventDefault();

			this.loading = true;

			let items = this.constancia;
			let formData = new FormData();

			for (let item in items) {
				// console.log(item, items[item])
				formData.append(item, items[item]);
			}
			
			this.pocketbase
				.collection('constancias_fiscal')
				.create(formData)
				.then( res => {
					
					this.complete = true;
					let { id } = res;

					alert(`Muchas gracias, se ha guardado correctamente con ID [${id}]`)
					
					setTimeout(() => {
						window.location.href = 'https://www.contacta.mx';
					}, 2500);
				})
				.catch( err => {
					
					setTimeout(() => {
						this.loading = false;	
					}, 2500);
					
					let { message, data: { data } } = err;

					if (data) {

						message += '\r\n\r\n';
						for (let error in data) {
							const param_error = error.split('_').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ')
							message += param_error + ': ' +  data[error]['message'] + '\r\n';
						}	
					}

					alert(message.trim())
				})

		}
	},
}
</script>