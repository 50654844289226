<template>
    <div class="row">
		<div class="col-md-12">
			
			<div class="row align-items-center justify-content-center">
				<div class="col-md-7">
					<div class="mb-4">
						<h5>Credenciales Requeridas</h5>
					</div>
					<form action="#" method="post" v-on:submit="loginAdmin">
								
						<div class="row mb-3">
							<div class="col-md-12">
								<label for="email" class="form-label">Usuario</label>
								<input type="email" class="form-control form-control-sm" id="email" placeholder="correo@ejemplo.com" v-model="username" minlength="3" autocomplete="new-username" required />
							</div>
						</div>
						
						<div class="row mb-3">
							<div class="col-md-12">
								<label for="password" class="form-label">Contraseña</label>
								<input type="password" class="form-control form-control-sm" id="password"  placeholder="********" v-model="password" minlength="3" autocomplete="new-password" required />
							</div>
						</div>
								
						<hr class="my-4" />

						<button class="w-100 btn btn-primary btn-xs" type="submit">
							<i class="bi bi-box-arrow-in-right"></i> Ingresar
						</button>

						<span class="d-block text-center my-4 text-muted">&mdash; or &mdash;</span>
						<div class="social-login">

							<a v-for="(item, key) in providers" v-bind:key="key" class="w-100 btn btn-success btn-xs" :href="item.href" v-on:click="setProvider(item)">
								<i class="bi" :class="'bi-' + item.name"></i> {{ item.name }}
							</a>

						</div>
					</form>
				</div>
			</div>


		</div>
	</div>

</template>

<script>

import usePocketbase from '../composables/usePocketbase';
export default {

	data() {
		return {
			username: '',
			password: '',
			list_state: [],
			providers: [],
			redirect_url: `${window.location.origin}/redirect`,
		}
	},
	mounted() {
		
		const { pocketbase } = usePocketbase();
		this.pocketbase = pocketbase;
		this.listAuthMethods();

	},

	methods: {

		loginAdmin(e){
			e.preventDefault();
			
			this.pocketbase
				.admins
				.authWithPassword(this.username, this.password)
				.then(res => {
					console.log(res)
					window.location.href = `${window.location.origin}/home`
				})
				.catch(err => console.log(err))

		},

		setProvider(item){
			localStorage.setItem('provider', JSON.stringify(item))
		},

		async listAuthMethods(){
			
			let authMethods = await this.pocketbase.collection('users').listAuthMethods();

			for(let provider of authMethods.authProviders){
				provider.href = provider.authUrl + this.redirect_url;
				this.providers.push(provider)
			}

		}
	},
}
</script>