import { createRouter, createWebHistory } from 'vue-router';

import useAuthUser from '../composables/useAuthUser';

import SituacionFiscalView from '../views/SituacionFiscalView';
import ListadoConstanciasView from '../views/ListadoConstanciasView';
import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import RedirectView from '../views/RedirectView';

const routes = [
	
    { path: "/", name: "index", component: HomeView, meta: { requiresAuth: true } },
    { path: "/home", name: "home", component: HomeView, meta: { requiresAuth: true } },

	{ path: '/listado-constancias', name: 'listadoConstanciasView', component: ListadoConstanciasView, meta: { requiresAuth: true } },
	{ path: '/constancia-fiscal', name: 'situacionFiscalView', component: SituacionFiscalView },

	{ path: '/redirect', name: 'redirectView', component: RedirectView },
	{ path: '/login', name: 'login', component: LoginView },
	{ path: '/logout', name: 'logout', beforeEnter: async () => {
		const { logout } = useAuthUser();
		await logout();
		
        return { name: "login" };
	}},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach( to => {
	const { isLoggedIn } = useAuthUser();

	if (to.meta.requiresAuth && !isLoggedIn()) {
		return { name: 'login' };  
	}
})

export default router