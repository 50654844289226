<template>
    <div class="container col-md-6">
        <main>
            <div class="row text-center mb-2">
                <img class="d-block mx-auto mb-2 img-responsive" src="./assets/logo.png" alt="Contacta Logo" />
                <h4>Administración Contacta</h4>
            </div>

            <router-view />
        </main>
		<footer class="my-5 pt-2 text-muted text-center text-small">
			<p class="mb-1">&copy; 2010–2023 Contacta &mdash; www.contacta.mx</p>
			<ul class="list-inline">
				<li class="list-inline-item">
					<router-link to="/constancia-fiscal">Constancia Fiscal</router-link>
				</li>
			</ul>
		</footer>

    </div>
</template>
