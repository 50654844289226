<template>
	<div id="content">
		redirect....
	</div>
</template>

<script>

import usePocketbase from '../composables/usePocketbase';

export default {
	data() {
		return {
			
			redirect_url: `${window.location.origin}/redirect`,
		}
	},
	mounted() {
		
		const { pocketbase } = usePocketbase();
		this.pocketbase = pocketbase;
		this.providerSignin();
	},
	methods: {
		providerSignin(){
				
			const params = (new URL(window.location)).searchParams;
			const provider = JSON.parse(localStorage.getItem('provider'))
			const redirect_url = this.redirect_url;

			// compare the redirect's state param and the stored provider's one
			if (provider.state !== params.get('state')) {
				throw "State parameters don't match.";
			}

			// console.log(params)
			// console.log(provider)
			// console.log(provider.state , params.get('state'))

			// authenticate
			this.pocketbase
			.collection('users')
			.authWithOAuth2( provider.name, params.get('code'), provider.codeVerifier, redirect_url, { emailVisibility: false })
			.then((authData) => {
				// document.getElementById('content').innerText = JSON.stringify(authData, null, 2);
				console.log(authData)
				window.location.href = `${window.location.origin}/home`
			})
			.catch((err) => {
				alert(err.message);
				window.location.href = `${window.location.origin}/login`
			});

		}
	},
}
</script>