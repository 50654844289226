
import usePocketbase from "./usePocketbase";


export default function useAuthUser(){
	
    const { pocketbase } = usePocketbase();

	// console.log(pocketbase)

    const getUser = () => {
        return pocketbase.authStore.model;
    };

    const isLoggedIn = () => {
        return pocketbase.authStore.token !== '';
    };

    const logout = () => {
        return pocketbase.authStore.clear();
    };

    return {
        // user, 
        // login,
		getUser,
        isLoggedIn,
        logout,
        // register,
        // update,
        // sendPasswordRestEmail,
        // maybeHandleEmailConfirmation,
    };
}